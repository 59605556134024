<template>
	<div class="container sub-top mb-30">
		<div>
			<div class="justify-space-between-pc gap-20">
				<div class="flex-1 flex-column justify-center ptb-30-pc size-px-14 font-weight-500">
					<div class="justify-space-between">
						<div><span class="size-px-24-pc size-px-20-m font-weight-bold color-primary">{{ user.member_id}}({{ user.member_name}})</span> 님 <span class="size-px-14-m">반갑습니다.</span></div>
						<button
							class="btn-round btn-outline hide-m"
							@click="toModify"
						>정보수정</button>
					</div>
				</div>
				<div class="flex-1 flex-column justify-center mt-10-m pa-20 box radius-20 ">
					<div class="justify-space-between  size-px-18-pc size-px-16-m items-center">
						<span class="font-weight-bold hide-m">마이 포인트</span>
						<span><span class="size-px-36-pc size-px-20-m font-weight-bold">{{ user.member_point | makeComma }} P</span> <button @click="reload"><v-icon large>mdi-refresh</v-icon></button></span>
					</div>
				</div>
			</div>
		</div>
		<div
			class="mt-30 hide-m"
		>
			<ul
				class="justify-space-between gap-20"
			>
				<li
					v-for="(menu, m_index) in items_menu"
					:key="'menu_' + m_index"
					class="pa-30-10 box radius-20 flex-1 text-center font-weight-bold size-px-16 bg-default cursor-pointer"
					:class="{ on: menu.title == item.title}"
					@click="toMenu(menu)"
				>
					{{ menu.title }}
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'SubTop'
		, props: ['user', 'program']
		, data: function(){
			return {
				items_menu: [
					{
						name: 'point'
						, title: '포인트 내역'
						, to: 'PointHistory'
					}
					, {
						name: 'point'
						, title: '포인트 결제 내역'
						, to: 'PointList'
					}
					, {
						name: 'present'
						, title: '포인트 선물'
						, to: 'PointPresent'
					}
					, {
						name: 'present'
						, title: '주문 내역'
						, to: 'OrderList'
						, params: {
							type: 'order'
						}
					}
					, {
						name: 'issue'
						, title: '취소/환불'
						, to: 'OrderCancel'
						, params: {
							type: 'cancel'
						}
					}
					, {
						name: 'qna'
						, title: '1:1문의'
						, to: 'CustomerCenter'
						, params: {
							b_code: 'b_qna'
						}
					}
					, {
						name: 'wish'
						, title: '관심상품'
						, to: 'Wish'
					}
				]
			}
		}
		, computed: {

			item: function(){
				let t = {
				}

				this.items_menu.filter( (item) => {
					if(item.to == this.$route.name){
						t = item
					}
				})

				return t
			}
		}
		, methods: {
			toModify: function(){
				this.$bus.$emit('to', {name: 'MyPage'})
			}
			, toMenu: function(item){
				this.$bus.$emit('to', {name: item.to, params: item.params})
			}
			, reload: function(){
				document.location.reload()
			}
		}
		, created() {

		}
	}
</script>

<style>

</style>