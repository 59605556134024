import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import VueDaumPostcode from "vue-daum-postcode"
import { storage } from "./resources/storage/storage"
import { date } from '@/resources/date/date'
import { request } from "@/resources/request/request";
import { rules } from "@/resources/rules/rules";
import {filter} from '@/resources/filter/filter'
import { common } from '@/assets/js/common'
import { codes } from '@/resources/config/codes'

Vue.config.productionTip = false
Vue.prototype.$date = date
Vue.prototype.$request = request
Vue.prototype.$rules = rules
Vue.prototype.$filter = filter

Vue.use(VueDaumPostcode)


if(window.location.href.indexOf(process.env.VUE_APP_DOMAIN) > -1){
  Vue.prototype.$production = true
  Vue.prototype.$production_title = process.env.VUE_APP_TITLE
}else{
  Vue.prototype.$production = false
  Vue.prototype.$production_title = process.env.VUE_APP_TITLE_DEV
}

Vue.prototype.$storage = storage
Vue.prototype.$pdt_img_url = ''
Vue.prototype.$server_url = process.env.VUE_APP_SERVER
Vue.prototype.$common = common
Vue.prototype.$codes = codes

Vue.prototype.$bus = new Vue()

Vue.prototype.$shop_logo = '/images/' + process.env.VUE_APP_SHOP + '/' + process.env.VUE_APP_LOGO

let location = window.location.hostname

Vue.prototype.$https = 'https://' + location


let domains = process.env.VUE_APP_DOMAIN
let dev = process.env.VUE_APP_DEV
let server = process.env.VUE_APP_SERVER

domains = domains.split('|')
for(let i = 0; i < domains.length; i++) {
  if (location.indexOf(domains[i]) > -1) {
    Vue.prototype.$baseUrl = server
    Vue.prototype.$danal_url = 'https://' + location + server
    break
  } else {
    Vue.prototype.$baseUrl = dev
    Vue.prototype.$danal_url = dev
  }
}


Vue.prototype.$https = 'https://' + location

let skin = "skin-G"
let skin_img = "/"

domains = process.env.VUE_APP_DOMAIN_SKIN_O.split('|')

for(let i = 0; i < domains.length; i++){
  if(location.indexOf(domains[i]) > -1) {
    skin = "skin-O"
    skin_img = "/skin-O/"
    break
  }
}

domains = process.env.VUE_APP_DOMAIN_SKIN_B.split('|')

for(let i = 0; i < domains.length; i++){
  if(location.indexOf(domains[i]) > -1) {
    skin = "skin-B"
    skin_img = "/skin-B/"
    break
  }
}

Vue.prototype.$skin = {
  name: skin
  , img: skin_img
}

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
