<template>
	<div class="container sub-bottom mb-30">
		<div
			class="mt-30 hide-pc"
		>
			<ul
				class=""
			>
				<li
					v-for="(menu, m_index) in items_menu"
					:key="'menu_' + m_index"
					class="ptb-15 justify-space-between under-line size-px-16 font-weight-600 items-center cursor-pointer"
					:class="{ on: menu.title == item.title}"
					@click="toMenu(menu)"
				>
					{{ menu.title }}

					<v-icon>mdi mdi-chevron-right</v-icon>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
export default {
	name: 'SubBottom'
	, props: ['user', 'program']
	, data: function(){
		return {
			items_menu: [
				{
					name: 'point'
					, title: '포인트 내역'
					, to: 'PointList'
				}
				, {
					name: 'present'
					, title: '포인트 선물'
					, to: 'PointPresent'
				}
				, {
					name: 'present'
					, title: '주문 내역'
					, to: 'OrderList'
					, params: {
						type: 'order'
					}
				}
				, {
					name: 'issue'
					, title: '취소/환불'
					, to: 'OrderCancel'
					, params: {
						type: 'cancel'
					}
				}
				, {
					name: 'qna'
					, title: '1:1문의'
					, to: 'CustomerCenter'
					, params: {
						b_code: 'b_qna'
					}
				}
				, {
					name: 'wish'
					, title: '관심상품'
					, to: 'Wish'
				}
			]
		}
	}
	, computed: {

		item: function(){
			let t = {
			}

			this.items_menu.filter( (item) => {
				if(item.to == this.$route.name){
					t = item
				}
			})

			return t
		}
	}
	, methods: {
		toModify: function(){
			this.$bus.$emit('to', {name: 'Modify'})
		}
		, toMenu: function(item){
			this.$bus.$emit('to', {name: item.to, params: item.params})
		}
	}
	, created() {

	}
}
</script>

<style>
.sub-top .on {
	background-color: var(--bg-base);
	border-color: var(--primary) !important;
	color: var(--primary);
}
.sub-top .bg-default{
	border-color: var(--bg-dark);
}
</style>