<template>
	<div class="full-height" :class="$skin.name">
		<Loading
			v-if="is_loading"
		></Loading>
		<div
			v-if="is_ready"
			class="full-height"
		>
			<Side
				v-if="is_side"
				:user="user"
				:shop_info="shop_info"
				:category="category"

				@toggleSide="toggleSide"
				@push="toLocation"
			>
			</Side>
			<div class="full-height">
				<Top
					v-if="!program.not_top"
					:program="program"
					:user="user"
					:shop_info="shop_info"

					@toggleSide="toggleSide"
					@push="toLocation"
				></Top>

				<hr v-if="program.top_line" class="under-line mb-50-pc mb-20-m"/>

				<Title
					v-if="program.title"
					:program="program"
					:cart_cnt="cart_cnt"

					@push="toLocation"
				></Title>

				<SubTop
					v-if="is_sub_top"
					:user="user"
				></SubTop>

				<router-view
					:cart_info="cart_items"
					:user="user"
					:shop_info="shop_info"
					:key="$route.fullPath"
					:codes="codes"
					:category="category"

					@getCartList="getCartList"
					@onLoad="setProgram"
					:class="{ 'full-height' : program.is_full, 'container': !program.is_container}"
				></router-view>

				<SubBottom
					v-if="is_sub_bottom"
					:user="user"
				></SubBottom>

				<Bottom
					v-if="program.bottom"

					:user="user"
					:shop_info="shop_info"
					:cart_cnt="cart_cnt"
					@push="toLocation"
					@toggleSide="toggleSide"
				></Bottom>
			</div>
		</div>

		<Popup v-if="false"></Popup>

		<Notify
			:msg="notifyCondition.message"
			:type="notifyCondition.type"

			@clearMsg="clearMsg"
		/>

		<PersonalConfirm
			:user="user"
		></PersonalConfirm>
	</div>
</template>

<script>

	import Notify from '@/components/AlertMsg'
	import Side from "@/view/Layout/Side";
	import Top from "@/view/Layout/Top";
	import Bottom from "@/view/Layout/Bottom";
	import Title from "@/view/Layout/Title";
	import Loading from "@/view/Layout/Loading";
	import SubTop from "@/view/Layout/SubTop";
	import SubBottom from "./SubBottom";
	import Popup from "@/view/Layout/Popup";
	import PersonalConfirm from "./PersonalConfirm";
	
	export default{
		name: 'Layout'
		,props: ['user', 'shop_info', 'codes', 'category_list']
		,components: {PersonalConfirm, Popup, SubBottom, SubTop, Loading, Title, Bottom, Side, Top, Notify }
		,data: function(){
			return {
				program: {
				}
				,notifyCondition: {
					message: '메시지 입니다'
					,type: ''
				}
				,is_side: false
				,cart_items: [

				]
				,is_loading: true
				,is_ready: false
				,category: null
			}
		}
		,computed:{
			cart_cnt: function(){
				return this.cart_items.length
			}
			, is_sub_top: function(){
				let t = false
				if(this.$route.path.indexOf('Account') > -1){
					t = true
				}
				return t
			}
			, is_sub_bottom: function(){
				let t = false
				if(this.$route.path.indexOf('Account') > -1){
					t = true
				}
				return t
			}
		}
		,methods: {

			getData: async function(){
				try{
					const result = await this.$request.init({
						method: 'get'
						,url: '/product/getCategory'
					})
					if(result.success){
						this.category = result.data
					}else{
						this.$bus.$emit('notify', { type: 'error', message: result.message})
					}
				}catch (e) {
					console.log(e)
				}finally {
					this.is_loading = false
					this.is_ready = true
				}
			}
			,setNotify: function({type, message}){
				this.notifyCondition = {
					message: message
					,type: type
				}
			}
			,clearMsg: function(){
				this.notifyCondition.message = ''
			}
			,toggleSide: function(){
				this.is_side = !this.is_side
			}
			,setProgram: function(program){
				this.program = program
			}
			,toBack: function(){
				this.$router.back()
			}
			, toLocation: function( {name, params, query}){
				this.$router.push({ name: name, params: params, query: query}).catch(function(e){
					console.log(e)
				});
			}
			,onLoading: function(){
				this.is_loading = true
			}
			,offLoading: function(){
				setTimeout(() => {
					this.is_loading = false
				}, 300)
			}
			,getCartList: async function(){
				try{
					const result = await this.$request.init({
						method: 'get'
						,url: 'order/getCartList'
					})

					if(result.success){
						this.cart_items = result.data.content.result
					}else{
						this.$bus.$emit('notify', { type: 'error', message: result.message})
					}
				}catch (e) {
					console.log(e)
				}
			}
		}
		,created: function(){

			this.getData()

			this.$bus.$on('on', (type) =>{
				if(type){
					this.onLoading()
				}else{
					this.offLoading()
				}
			})

			this.$bus.$on('onLoad', (program) => {
				this.setProgram(program)
			})
		}
		,watch: {
			$route(){
				this.is_side = false
			}
		}
	}
	
</script>
<style>

@import "../../assets/css/reset.css";
@import "../../assets/css/template.css";
@import "../../assets/css/templateO.css";
@import "../../assets/css/templateB.css";
@import "../../assets/css/base.css";
@import "../../assets/css/baseO.css";
@import "../../assets/css/baseB.css";
</style>