<template>
	<PopupLayer
		v-if="is_on_confirm"
	>
		<template
			v-slot:body
		>
			<div class=" bg-white radius-top-20">
				<div class="pa-20 bg-popup-title flex-row justify-space-between items-center under-line">
					<h4>본인 인증</h4>
				</div>

				<div class=" pa-50-10">
					본인 인증 기간이 만료되었습니다
				</div>
				<div class="pa-10 text-center">
					<button
						class="btn btn-outline "

						@click="onConfirm"
					>본인 인증</button>
				</div>
			</div>
		</template>
	</PopupLayer>
</template>

<script>
	import PopupLayer from "./PopupLayer";
	export default{
		name: 'PersonalConfirm'
		, props: ['user']
		, components: {PopupLayer}
		, data: function(){
			return {
				is_on_confirm: false
			}
		}
		, methods: {
			onConfirm: function(){

				let baseUrl = this.$baseUrl
				let type = 'confirm'

				this.nice_url = baseUrl + '/nice/request?type=' + type + '&P=' + sessionStorage.getItem(process.env.VUE_APP_NAME + 'AT')

				window.open(this.nice_url, 'nice', 'width=320px')
			}
			, niceResult: function(e){
				console.log('niceResult', e.data)
				try {
					let domains = process.env.VUE_APP_DOMAIN.split('|')
					domains.push('localhost')
					let t = false
					for(let i = 0; i < domains.length; i++){
						if(e.origin.indexOf(domains[i]) > -1){
							t = true
						}
					}
					if(t){
						let data = e?.data
						if(data && typeof data == 'string'){
							data = JSON.parse(data)

							if(data.type == 'nice_result'){
								if(data.result){
									this.is_on_confirm = false
									this.$bus.$emit('notify', { type: 'success', message: '본인인증이 완료되었습니다'})
									this.$bus.$emit('setUser', [{ key: 'last_confirm', val: 1}])
								}else{
									throw data.message
								}
							}
						}
					}
				}catch (e) {
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					// this.is_on_confirm = false
				}
			}
		}
		, destroyed() {
			window.removeEventListener('message', this.niceResult)
		}
		, created() {

			window.removeEventListener('message', this.niceResult)
			setTimeout( () => {
				window.addEventListener('message', this.niceResult)
			}, 100)
		}
		, watch: {
			$route(){
				setTimeout( () => {
					if(this.user.member_id && this.user.last_confirm != 1){
						this.is_on_confirm = true
					}
				}, 300)
			}
		}
	}
</script>